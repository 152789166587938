import React from 'react'

export const TPELogomark = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="85.998"
    viewBox="0 0 86 85.998"
    role="img"
    aria-labelledby="tpeLogomarkTitle tpeLogomarkDesc"
  >
    <title id="tpeLogomarkTitle">Two Perfect Events & Weddings logo</title>
    <desc id="tpeLogomarkDesc">
      Two number 2s mirrored horizontally in the shape of heart centered in a purple circle.
    </desc>
    <g transform="translate(-88 -90.761)">
      <path
        d="M19.57,8.251v.477c1.951,0,4.193-2.057.619-5.922C15.582-2.183,14.043-4.543,9.3-5.945,5.2-7.047,1.66-6.185-.751-2.467c0,0-5.952,8.486,5.347,17.889,5.041,4.193,7.991,5.645,10.1,7.38,4.33,3.56,4.027,5.425,4.027,5.425S12.6,25.113,7.954,26.22C4.961,26.932,5.5,27.5.571,29.9a14.315,14.315,0,0,1-5.747,1.338c-.049-.018-.088-.037-.129-.055a15.5,15.5,0,0,1-7.076-6.605,26.075,26.075,0,0,0,1.142,3.911,34.982,34.982,0,0,1-3.613-2.19l-.069-.046c1.315,1.345,2.735,3.466,4.736,4.5.134.066.4.192.4.192a20.147,20.147,0,0,0,7.889,1.475A10.79,10.79,0,0,0,.532,32.3c3.62-.564,4.378-1.776,8.6-3.844.908-.444,7.323-2.729,11.812,4.154,0,0,.252-4.3-4.191-9C14.5,21.23-1.537,8.883,2.067.17c1.248-3.02,5.055-5.327,8.7-3.654,2.661,1.221,6.348,4.211,9.5,7.292,3.368,3.306-.155,4.442-.691,4.442"
        transform="translate(111.921 120.151)"
        fill={color || '#5d4788'}
      />
      <path
        d="M20.914,18.423a35.1,35.1,0,0,1-3.6,2.188A25.862,25.862,0,0,0,18.451,16.7a15.489,15.489,0,0,1-7.058,6.6c-.039.018-.081.037-.131.055a14.2,14.2,0,0,1-5.732-1.338c-4.918-2.4-4.377-2.965-7.363-3.675-4.635-1.107-10.741,2-10.741,2s-.3-1.863,4.017-5.423c2.1-1.737,5.043-3.189,10.07-7.382,11.273-9.4,5.331-17.89,5.331-17.89C4.438-14.063.9-14.922-3.194-13.822c-4.729,1.4-6.318,3.762-10.916,8.753C-17.673-1.2-15.5.853-13.557.853V.377c-.537,0-3.982-1.139-.623-4.445,3.136-3.079,6.865-6.072,9.516-7.293,3.643-1.673,7.45.637,8.693,3.657,3.6,8.714-12.4,21.059-14.647,23.44-4.432,4.695-4.178,9-4.178,9,4.48-6.881,10.879-4.6,11.785-4.154,4.211,2.069,4.967,3.277,8.58,3.84a10.68,10.68,0,0,0,2.419.1,20.062,20.062,0,0,0,7.872-1.457s.265-.126.394-.195c2.006-1.026,3.415-3.13,4.729-4.479a.145.145,0,0,0-.071.032"
        transform="translate(145.015 128.028)"
        fill={color || '#371863'}
      />
      <path
        d="M47.489,24.119a41.347,41.347,0,0,1-3.277,16.215,41.493,41.493,0,0,1-8.927,13.243A41.519,41.519,0,0,1,5.824,65.78a41.508,41.508,0,0,1-29.458-12.2,41.493,41.493,0,0,1-8.927-13.243,41.787,41.787,0,0,1,0-32.433A41.528,41.528,0,0,1-23.634-5.344a41.544,41.544,0,0,1,29.458-12.2A41.695,41.695,0,0,1,44.212,7.9a41.358,41.358,0,0,1,3.277,16.218M45.449,7.382A43.151,43.151,0,0,0,22.563-15.5a42.763,42.763,0,0,0-16.739-3.38A43.034,43.034,0,0,0-33.794,7.382a43.1,43.1,0,0,0,0,33.474,42.874,42.874,0,0,0,9.212,13.669A42.863,42.863,0,0,0,5.824,67.117a42.76,42.76,0,0,0,16.739-3.377A43.151,43.151,0,0,0,45.449,40.856a43.185,43.185,0,0,0,0-33.474"
        transform="translate(125.176 109.642)"
        fill={color || '#5d4788'}
      />
    </g>
  </svg>
)
