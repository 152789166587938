module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-74237051-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"10103575073575779"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#694a86","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Two Perfect Events & Weddings","short_name":"Two Perfect Events & Weddings","description":"Creative wedding & event design, planning, and more. Gracefully executed.","start_url":"/","background_color":"#694a86","theme_color":"#694a86","display":"minimal-ui","icon":"./static/favicon/favicon-11.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dda8b95af0a0c24c03bea25c04face43"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
