import React from 'react'

export const IconSquiggly = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.046 222.337">
    <g transform="translate(111.046 211.897) rotate(173)">
      <path
        d="M84.783,213.488a133.927,133.927,0,0,1-29.832-11.379A89.483,89.483,0,0,1,29.688,182.4a76.279,76.279,0,0,1-15.567-28.052q-1.18-3.859-1.991-7.81a60.275,60.275,0,0,1-1.117-8.042,75.629,75.629,0,0,1,.578-16.131,62.409,62.409,0,0,1,4.252-15.639A37.7,37.7,0,0,1,25.2,93.217a27.185,27.185,0,0,1,7.073-4.547,23.238,23.238,0,0,1,8.255-1.96A30.592,30.592,0,0,1,56.61,90.643a36.815,36.815,0,0,1,12.338,10.889,27.368,27.368,0,0,1,5.366,15.726,22.467,22.467,0,0,1-1.627,8.358,19.321,19.321,0,0,1-4.8,7.155,16.721,16.721,0,0,1-7.766,3.957,25.211,25.211,0,0,1-8.447.288,51.66,51.66,0,0,1-15.551-4.867,72.163,72.163,0,0,1-13.746-8.5,63.075,63.075,0,0,1-11.314-11.594A54.246,54.246,0,0,1,3.41,97.711C-.246,87.414-.631,76.31.7,65.719A152.5,152.5,0,0,1,8.448,34.78q1.309-3.755,2.721-7.462l.671-1.753.329-.85.663-1.7,1.348-3.386q-4.964.1-9.942.277C10.851,13.008,17.578,6.578,24.213,0c.085,9.35,0,18.743.326,27.88q-3.561-3.427-7.074-6.929l-1.338,3.361-.657,1.683-.326.843-.664,1.736q-1.395,3.67-2.689,7.372A149.023,149.023,0,0,0,4.218,66.156c-1.29,10.228-.89,20.752,2.53,30.375a50.635,50.635,0,0,0,7.158,13.41,59.5,59.5,0,0,0,10.683,10.941,68.657,68.657,0,0,0,13.058,8.068A48.168,48.168,0,0,0,52.1,133.5a21.723,21.723,0,0,0,7.257-.229,13.166,13.166,0,0,0,6.145-3.114,15.814,15.814,0,0,0,3.9-5.861,18.931,18.931,0,0,0,1.373-7.046,23.83,23.83,0,0,0-4.713-13.67,33.281,33.281,0,0,0-11.141-9.827,27.1,27.1,0,0,0-14.2-3.508A21.535,21.535,0,0,0,27.546,95.87,34.187,34.187,0,0,0,19.1,108.125a58.912,58.912,0,0,0-4,14.748,71.942,71.942,0,0,0-.551,15.367,56.691,56.691,0,0,0,1.053,7.586q.782,3.787,1.909,7.485a72.73,72.73,0,0,0,14.847,26.76,85.956,85.956,0,0,0,24.275,18.919,130.455,130.455,0,0,0,29.04,11.07Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
)
